import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { apiRoutes } from "../../../../../../api/apiRoutes";

const LiveFootageCard = (props) => {
  let codeExecuted=false
  const {flagAPI,videoFeedAPI,insertDataAPI}=apiRoutes()

  

  async function handleSnapshot(){
    props.detectCircle()
    .then(()=>{
        setInterval(()=>{
          async function flag(){
            const res=await axios.get('https://cd7429dc50b7.ngrok.app/flag')
            
            console.log(res.data)

            if(!codeExecuted&&res.data==='finishing operations'){
              async function insertData(){
                const res=await axios.get('https://cd7429dc50b7.ngrok.app/insert_data')
                console.log("insert data", res)
                codeExecuted=true
              }
              insertData()
            }

          
            
          }
          flag()
        },3000)
  }
    )


}





  return (
    <Fragment>
      <h5>
        <strong>Live Footage of pipe counting</strong>
      </h5>

      <div className="row" style={{ margin: 0 ,display:"flex",flexDirection:"column",justifyContent:"center"}}>
        <img src="https://cd7429dc50b7.ngrok.app/video_feed" width='100%'/>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
        <button onClick={handleSnapshot} style={{backgroundColor:"grey",borderRadius:"10px",color:"#fff", marginTop:"10px", border:"none",outline:"none",padding:"10px", cursor:"pointer"}}>Take Snapshot</button>
        </div>
      </div>
    </Fragment>
  );
};

export default LiveFootageCard;

import React from "react";

import styles from "./OverViewStyles.module.css";

const OverView = (props) => {
  const totalPipes = props.tables.reduce(
    (a, b) => a + parseInt(b.Total_Pipes_per_Vehicle),
    0
  );

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <h5 style={{ fontSize: "40px" }}>
        <strong>Welcome, Kriti Industries</strong>
      </h5>
      <p style={{ fontSize: "18px" }}>Welcome back to AlVision Pi !</p>

      <div className="row" style={{ margin: "1px" }}>
        <div className={"col-6 p-2 ml-2 " + styles.innerBlock} align="center">
          <span className={styles.innerBlockValues}>
            <i className="fa-solid fa-video"></i>
            <span className="ml-4">
              <strong>1</strong>
            </span>
          </span>
          <br />
          <span>Camera count</span>
        </div>

        <div className={"col-5 p-2 ml-2 " + styles.innerBlock} align="center">
          <span className={styles.innerBlockValues}>
            <i className="fa-solid fa-chart-line"></i>
            <span className="ml-4">
              <strong>{totalPipes}</strong>
            </span>
          </span>
          <br />
          <span>Pipe count</span>
        </div>
      </div>

      <div className="row" style={{ margin: "1px", marginTop: "8px" }}>
        <div className={"col-6 p-2 ml-2 " + styles.innerBlock} align="center">
          <span className={styles.innerBlockValues}>
            <i className="fa-sharp fa-solid fa-truck"></i>
            <span className="ml-4">
              <strong>{props.tables.length}</strong>
            </span>
          </span>
          <br />
          <span>Total Vehical count</span>
        </div>
      </div>
    </div>
  );
};

export default OverView;

import { Fragment, React, useEffect, useState } from "react";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import styles from "./ReportsStyle.module.css";
import DashboardPages from "../../DashboardConstants";
import axios from "axios";
import { IconButton, Tooltip } from "@mui/material";
import { apiRoutes } from "../../../../../api/apiRoutes";

let title;
// let time = "From 1st Sept 2022 to 30th Sept 2022";

let tableData = {};

const data_PipeReports = {
  Headers: [
    "ID",
    "Date",
    "Time",
    "Original Image",
    "Process Image",
    "Total Pipes Per Vehicle",
    // "Manual Count",
    // 'Difference'
  ],
  data: [
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
  ],
};

const SetData = (props) => {
  title = props.tableRequest;
  switch (props.tableRequest) {
    case DashboardPages.Reports.PIPESREPORTS:
      tableData = data_PipeReports;
      break;
    default:
      tableData = data_PipeReports;
  }
};

const Reports = (props) => {
  const [pageNumber,setPageNumber]=useState(0)
  const [totalData,setTotalData]=useState()
  const dataPerPage=10
  const pageCount=Math.ceil(totalData/dataPerPage)
  
  const handleNextPage=()=>{
    if(pageNumber<pageCount-1){
    setPageNumber(pageNumber+1)
    }
  }
  const handlePreviousPage=()=>{
    if(pageNumber>=0){
    setPageNumber(pageNumber-1)}
  }
  const timestamp=new Date()
  const date=timestamp.toLocaleDateString()
  const time=timestamp.toLocaleTimeString()
  SetData(props);

  const [table, setTable] = useState([]);

  // const [manualCounts, setManualCounts] = useState([]);
  // console.log(manualCounts)
  // console.log(JSON.stringify(manualCounts))


  //   const handleManualCountChange = (_id, pipeCount, manualCount) => {
  //   let updatedCounts = [...manualCounts];
  //   const countIndex = updatedCounts.findIndex((count) => count._id === _id);

  //   if (countIndex !== -1) {
  //     updatedCounts[countIndex] = {
  //       _id,
  //       pipeCount: pipeCount,
  //       manualCount: manualCount,
  //       difference:parseInt(manualCount)-parseInt(pipeCount)
  //     };
  //   } else {
  //     updatedCounts.push({
  //       _id,
  //       pipeCount: pipeCount,
  //       manualCount: manualCount,
  //       difference:parseInt(manualCount)-parseInt(pipeCount)
  //     });
  //   }

  //   setManualCounts(updatedCounts);
  // };




  // async function saveManualCounts(){
  //   console.log('saving manual counts')
  //   if(manualCounts.length===0){
  //     alert("Enter a manual count to save")
  //   }else{
  //   const res=await axios.post('http://localhost:9002/save_manual_count',manualCounts)
  //   console.log(res)}
  // }


  useEffect(() => {
    async function getData() {
      await axios
        .get(`https://cd7429dc50b7.ngrok.app/data/${pageNumber}`)
        .then((res) =>{ 
          console.log('report',res.data[0])
          setTable(res.data[0]);
          setTotalData(res.data[1])
          
        })
    }

    getData();
  }, [pageNumber]);

  async function downloadReport(){
    const res=await axios.get('https://66cbe1055074.ngrok.app/download_report')
    console.log('download report',res.data)
    if(res.data==='File Downloaded'){
      window.alert(res.data)
    }
    var byteCharacters = atob(res.data);
    var byteNumbers = new Array(byteCharacters.length);

    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const url = window.URL.createObjectURL(blob);
    let downloadBtn = document.getElementById("downloadReport");
    let aTag = document.createElement("a");
    aTag.href = url;
    aTag.download = `Report_${date}_${time}`;
    aTag.click();
    downloadBtn.appendChild(aTag);
    aTag.remove();
  }

  const downloadOriginal = (base64String, fileName) => {
    var byteCharacters = atob(base64String);
    var byteNumbers = new Array(byteCharacters.length);

    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    const url = window.URL.createObjectURL(blob);
    let downloadBtn = document.getElementById("downloadOriginal");
    let aTag = document.createElement("a");
    aTag.href = url;
    aTag.download = `${fileName}-Original`;
    aTag.click();
    downloadBtn.appendChild(aTag);
    aTag.remove();
  };

  const downloadProcessed = (base64String, fileName) => {
    var byteCharacters = atob(base64String);
    var byteNumbers = new Array(byteCharacters.length);

    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    const url = window.URL.createObjectURL(blob);
    let downloadBtn = document.getElementById("downloadProcessed");
    let aTag = document.createElement("a");
    aTag.href = url;
    aTag.download = `${fileName}-Processed`;
    aTag.click();
    downloadBtn.appendChild(aTag);
    aTag.remove();
  };

  return (
    <Fragment>
      <div className="row" style={{ marginRight: 0, marginLeft: 0 }}>
        <div className={styles.ReportsTitle + " col"}>
          <h5 style={{ fontWeight: 700 }}>{"KRITI INDUSTRIES LIMITED"}</h5>
          <h5>
            <strong>{title}</strong>
          </h5>
          <h6 style={{ fontSize: "20px" }}>Daily Report</h6>
        </div>
      </div>

      <div
        className="row"
        style={{
          marginRight: 0,
          marginLeft: 0,
          border: "1px solid #000",
          borderLeft: "0",
          borderRight: "0",
          padding: "15px 50px",
          marginBottom: "20px",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Tooltip title="Print">
          <IconButton
            sx={{
              borderRadius: 0,
              backgroundColor: "#808080",
              "&:hover": { backgroundColor: "#808080" },
            }}
            id='downloadReport'
            onClick={()=>downloadReport()}
          >
            <LocalPrintshopOutlinedIcon
              sx={{ fontSize: "30px", color: "#fff" }}
            />
          </IconButton>
        </Tooltip>
      </div>

      <div
        className={"row mt-2 " + styles.reportsTable}
        align="center"
        style={{ marginRight: 0, marginLeft: 0 }}
      >
        <div className="col pr-4">
       
          <table className="table table-bordered">
      <thead>
        <tr>
          {tableData.Headers.map((name, id) => (
            <th key={id}>{name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {table.map((data, id) => {
       
          return (
            <tr key={id}>
              <td>{data.id}</td>
              <td>{data.Date}</td>
              <td>{data.Time}</td>
              <td>
                <button
                  id='downloadOriginal'
                  onClick={() => downloadOriginal(data.Original_Image, data.id)}
                  style={{backgroundColor:"gray",padding:"10px",color:"#fff",borderRadius:"10px",border:"none",outline:"none"}}
                >
                  {data.id}
                </button>
              </td>
              <td>
                <button
                  id='downloadProcessed'
                  onClick={() => downloadProcessed(data.Process_Image, data.id)}
                  style={{backgroundColor:"gray",padding:"10px",color:"#fff",borderRadius:"10px",border:"none",outline:"none"}}
                >
                  {data.id}
                </button>
              </td>
              <td>{data.Total_Pipes_per_Vehicle}</td>
              {/* <td>
               Manual Count
              </td>
              <td>Difference</td> */}
            </tr>
          );
        })}
      </tbody>
    </table>



    

Page {pageNumber+1} of {pageCount}
          <button onClick={handlePreviousPage} disabled={pageNumber<=0} style={{margin:"10px",padding:"10px",backgroundColor:"gray",borderRadius:"10px",border:"none",outline:"none",color:"#fff"}}>Previous</button>
          <button onClick={handleNextPage} style={{margin:"10px",padding:"10px",backgroundColor:"gray",borderRadius:"10px",border:"none",outline:"none",color:"#fff"}}>Next</button>
        </div>
      </div>
    </Fragment>
  );
};

export default Reports;
